.actionContainer {
    width: 100%;
    height: 88px;
    padding: 24px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filtersWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.displayNone {
    transition: height 300ms ease-out !important;
    opacity: 0 !important;
    height: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
}

.displayBlock {
    transition: height 300ms ease-in !important;
    opacity: 1 !important;
    height: 88px !important;
    padding: 24px 64px !important;
}

.dbHeaderHfbDropdown .ant-checkbox-group {
    height: 300px;
    overflow-y: scroll;
}

.dbHeaderHfbDropdown .ant-checkbox-checked .ant-checkbox-inner:after {
    margin-left: 0px;
    margin-top: -1px;
}

.dbHeaderSortDropdown {
    width: 250px;
}

.dbRadioButtonWrapper {
    width: 100% !important;
    margin-bottom: 24px;
}

.dbRadioButtonWrapper .radio {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    width: auto !important;
    justify-content: space-between;
}

.dbRadioButtonWrapper .radio label {
    padding-inline-start: 0 !important;
}

.dbRadioButtonWrapper .radio input[type=radio] {
    left: auto !important;
}