.csvInvalidPanelWrapper>* {
    animation-duration: 0s !important;
}

.ant-drawer-header {
    border-bottom: 0px !important;
    padding: 22px 24px !important;
}

.ant-drawer-content-wrapper {
    width: 480px !important;
}

.ant-drawer-content {
    border-radius: 8px 0px 0px 8px;
}

.ant-drawer-header-title {
    flex-direction: row-reverse;
    justify-content: center;
    width: 100%;
    flex: none;
}

.ant-drawer-close {
    margin-right: 0px !important;
    position: absolute;
    top: 12px;
    right: 12px;
}

.ant-drawer-title {
    font-weight: 700 !important;
    text-align: center;
}

.csvAccordionItem * {
    font-family: 'Noto IKEA Latin';
}

.csvAccordionItem .accordion__content {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.csvAccordionItem .accordion__content>div {
    line-height: 24px;
}