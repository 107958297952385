.sectionWrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
}

.dateLabel {
    margin-bottom: 2px;
    margin-top: 32px;
    line-height: 24px;
}

.icon {
    color: #111111;
    height: 20px;
    width: 20px;
}

.radioButton {
    width: 100% !important;
    margin-top: 40px;
}

.dateInput {
    height: 48px;
    width: 100%;
    max-height: 48px !important;
    border-radius: 4px;
    border: 1px solid #929292;
    transition: box-shadow 100ms cubic-bezier(0.4, 0, 0.4, 1), border 100ms cubic-bezier(0.4, 0, 0.4, 1);
}

.dateInput:hover {
    border-color: #929292;
}

.dateInput:focus-within {
    box-shadow: none;
    border: 2px solid #0058a3;
}