.pillsContainer {
    width: calc(100vw - 128px);
}

.pillsContainer::-webkit-scrollbar {
    height: 6px;
}

.pillsContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(169 169 169 / 20%);
}

.pillsContainer::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
    border-radius: 8px;
    cursor: grab;
}

.pill {
    margin-right: 16px;
    margin-bottom: 16px;
    max-height: 40px;
    min-width: fit-content;
}

.pillBorder {
    border: 1px solid #111111;
}

.fcTotalCount {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: inline-flex;
    margin-top: 10px;
    margin-left: 4px;
    color: #767676;
}