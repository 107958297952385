.paginationWrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}

.paginationText {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
}

.paginationWrapper .ant-progress-line {
    width: 280px !important;
    margin-top: 12px !important;
    margin-bottom: 24px !important;
}

.ant-progress-bg,
.ant-progress-success-bg {
    height: 3px !important;
    background-color: #111111 !important;
}