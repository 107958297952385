.emptyMainText {
    font-size: 24px;
    line-height: 32px;
}

.emptyMainTextSmall {
    font-size: 16px;
    line-height: 20px;
}

.emptySubText {
    margin-top: 0px;
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: column;
    color: #929292;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;;
}

.emptyDataIcon {
    width: 90px !important;
    height: 90px !important;
    color: #DFDFDF;
}

.emptyDataIconSmall {
    width: 64px !important;
    height: 64px !important;
    color: #DFDFDF;
}

.emptyData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    gap: 12px;
}

.emptyDataSmall {
    height: 200px;
}