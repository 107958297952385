.mobileNextButton, .btIcSaveComparison {
    position: absolute;
    width: 86%;
    height: 56px;
    bottom: 25px;
    left: 7%;
    right: 7%;
    background: white;
}

.mobileNextButton>span {
    height: 56px;
}

.btIcSaveComparison {
    position: absolute;
    height: 56px;
    width: 86%;
    bottom: 25px;
    left: 7%;
    right: 7%;
    background: #FFFFFF;
    border-radius: 999px;
}

.btIcSaveComparison>span {
    height: 56px;
}

.mobileSectionTitle {
    font-family: Noto IKEA Latin;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: #000000;
}

.mobileSectionDescription {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
    color: #000000;
}

.disabled {
    border: 1px solid #dfdfdf !important;
}