.pmParent .theatre {
    border-radius: 8px !important;
    left: 22.5% !important;
    height: calc(100% - 4rem) !important;
    width: 55% !important;
}

@media only screen and (max-width: 1200px) {
    .pmParent .theatre {
        border-radius: 8px !important;
        left: 10% !important;
        height: calc(100% - 4rem) !important;
        width: 80% !important;
    }
}

@media only screen and (max-width: 800px) {
    .pmParent .theatre {
        border-radius: 8px !important;
        top: 0 !important;
        left: 0 !important;
        height: 100% !important;
        width: 100% !important;
    }
}

.accordion__content {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 24px 16px;
}

.divider_vertical {
    height: 26px;
    margin: 0px 0px 0px 16px;
    border: 0;
    border-right: 1px solid #DFDFDF;
}

.demo-divider__hr-horizontal {
    margin: 0px 0px 32px 0px;
    border: 0;
    border-top: 1px solid #DFDFDF;
}

.removeItem {
    text-decoration: line-through;
    color: #484848;
}

.table-number {
    text-align: right !important;
  }