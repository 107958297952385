.fcContentWrapper {
    width: 100%;
    padding: 0px 64px;
    margin-top: 18px;
    height: calc(100vh - 120px);
}

.filters-container .ant-select {
    height: 40px !important;
    background: #F5F5F5 !important;
    border: none !important;
    border-radius: 50px !important;
    padding: 0px;
}

.filters-container .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px !important;
    background-color: none !important;
    border: none !important;
    border-radius: 50px !important;
    background: #F5F5F5 !important;
    box-shadow: none !important;
    padding: 0px 16px;
}

.filters-container .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    background: #dfdfdf !important;
}

.filters-container .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer !important;
    caret-color: transparent;
}

.fcHfbFieldContainer {
    position: relative;
}

.compRightPadding {
    padding-right: 30px !important;
}

.compLeftPadding {
    padding-left: 75px !important;
}

.fcHfbPlaceholder {
    position: absolute;
    padding: 0px 24px;
    color: #111111;
    font-weight: bold;
    font-size: .76rem;
    left: 0;
    top: calc(50% - 10px);
    line-height: 19px;
    pointer-events: none;
}

.filters-container .ant-select-arrow {
    color: #111111 !important;
}

.filters-container .ant-select-selection-overflow {
    display: none !important;
}

.filters-container .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: #0058A3 !important;
    border-color: #0058A3 !important;
}

.filters-container .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
    background-color: #0058A3 !important;
}

.filters-container .ant-select-dropdown {
    min-width: 450px !important;
}

.filters-container .ant-tree-select-dropdown {
    padding: 12px 4px 12px 12px !important;
}

.filters-container .ant-select-tree-list-holder {
    max-height: 340px !important;
}

.filters-container .ant-select-tree-switcher {
    margin-right: 4px !important;
}

.filters-container .ant-table-column-sorter {
    display: none;
}

.sortIcon {
    width: 25px;
    color: #484848;
    margin-left: 6px;
}

.pills-container {
    display: flex;
    justify-content: space-between;
}

.demo-divider__hr-horizontal {
    margin: 24px 0px;
    border-block-start: #DFDFDF;
}

.fcHeaderWrapper {
    width: calc(100vw - 128px);
    background-color: #FFFFFF;
}

.flexbox {
    justify-content: space-between;
    align-items: center;
}

.filters-container {
    display: flex;
    gap: 16px;
}

.search_pill_fc {
    width: 392px;
    margin-left: auto;
}

.search_pill_fc .search__icon {
    display: none;
}

.search_pill_fc .search__action-wrapper:last-child {
    display: none;
}

.table-align-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.fc-demo-divider__hr-horizontal {
    border: 0;
    border-top: 1px solid #DFDFDF;
    margin: 24px 0px 24px 0px !important;
}

.filters-container .ant-table-empty .ant-table-tbody>tr.ant-table-placeholder>td {
    background-color: #FFFFFF !important;
}

.btnShowMore {
    width: 280px;
}

.fcRUSUFieldContainer {
    position: relative;
}

.fcRUSUPlaceholder {
    position: absolute;
    padding: 0px 24px;
    color: #111111;
    font-weight: bold;
    font-size: .76rem;
    left: 0;
    top: calc(50% - 10px);
    line-height: 19px;
    pointer-events: none;
}

.datePicker {
    visibility: hidden;
    height: 0;
    padding: 0;
    width: 0;
    position: absolute;
}

.fcTitle {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #111111;
}

.allFiltersContainer {
    display: flex;
    flex-direction: column;
    height: 863px;
    padding: 32px 24px;
}

.allFilterHfb .ant-select {
    height: 40px;
    background: none !important;
    border: none !important;
    padding: 0px 16px;
}

.fcContentWrapper .allFilterHfb .ant-select-dropdown {
    min-width: 300px !important;
}

.site-calendar-demo-card {
    width: 100%;
    border: 1px solid #f0f0f0;
}

.allFilterHfb .ant-tree-switcher {
    width: 24px !important;
}

.allFilterHfb .ant-tree-treenode .ant-tree-treenode-switcher-close {
    padding: 0px 16px !important;
}

.fcDate {
    visibility: hidden;
}

.fcDate .ant-popover-inner-content {
    padding: 0 !important;
}

.ant-popover-arrow {
    width: 0 !important;
}

.ant-picker-calendar-header {
    display: flex;
    justify-content: space-evenly;
    padding: 12px 0;
    align-items: center;
}

.ant-picker-calendar-header .ant-picker-calendar-year-select {
    align-items: center;
    display: flex;
    padding: 0px 8px;
}

.ant-picker-calendar-header .ant-picker-calendar-month-select {
    align-items: center;
    display: flex;
    padding: 0px 8px;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    display: none !important;
}

.site-calendar-demo-card .ant-select-dropdown {
    min-width: 0px !important;
}

.fcHfbTree {
    min-width: 450px !important;
}

.fcRuSuTree {
    min-width: 370px !important;
}

.fcDateDropdown {
    top: 230px !important;
}

.fcAllCheckBoxWrapper {
    width: 100%;
}

.fcAllCheckBoxWrapper .ant-menu-item-group-list {
    margin-bottom: 1.5rem !important;
}

.fcAllCheckBoxWrapper .ant-checkbox+span {
    padding-left: 0px !important;
}

.fcAllCheckBoxWrapper .ant-menu-item-group-title {
    font-family: 'Noto IKEA Latin' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #000000;
    padding: 0 !important;
    margin-bottom: 1.5rem !important;
}

.fcAllCheckBoxWrapper .ant-checkbox-group-item {
    margin-right: 0px !important;
}

.fcAllCheckBoxWrapper .ant-checkbox-group-item:last-child {
    margin-right: 0px !important;
}

.fcCompetitorListWrapper {
    background: #FFFFFF !important;
    border: 1px solid #929292 !important;
    height: 48px;
    width: 100%;
}

.fcCompetitorListWrapper .ant-picker-dropdown .ant-picker-dropdown-placement-bottomLeft {
    min-width: 0 !important;
}

.fcCompetitorListWrapper .ant-select-selection-overflow {
    display: flex !important;
}

.fcCompetitorListWrapper .ant-select {
    height: 45px !important;
    background: #FFFFFF !important;
    padding: 0px !important;
}

.fcCompetitorListWrapper .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0px 8px !important;
}

.fcRadioButtonWrapper {
    width: 100%;
}

.fcRadioButtonWrapper .radio {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    width: auto !important;
    justify-content: space-between;
}

.fcRadioButtonWrapper .radio label {
    padding-inline-start: 0 !important;
}

.fcRadioButtonWrapper .radio input[type=radio] {
    left: auto !important;
}

.fcMyCompCheckBoxWrapper {
    flex-direction: row;
    align-items: center;
    padding: 16px 0px;
    margin-bottom: 8px;
    gap: 16px;
}

.fcMyCompCheckBoxWrapper .ant-checkbox+span {
    padding-left: 0px !important;
}

.fcMyCompCheckBoxWrapper .ant-checkbox+span:hover {
    text-decoration: underline;
}

.fcMyCompCheckBoxWrapper .ant-checkbox-wrapper {
    padding: 0 !important;
}