.notification-wrapper {
   height: 100% !important;
   width: 100% !important;
}

.notification-wrapper>.sheets__content-wrapper div:first-child {
   padding-top: 1px !important;
   width: 100% !important;
}

.notification-wrapper>.sheets__content-wrapper:focus-visible {
   outline: none;
}

.notifBottomWrapper{
   margin-bottom: 20px;
   align-self: center;
}

.notificationHead {
   font-size: 23px !important;
   color: #111111;
   display: flex;
   justify-content: space-between;
   padding: 24px 0 !important;
   position: fixed;
   z-index: 9999;
   width: 100%;
   top: 0;
   font-weight: bold;
   background-color: white;
}

.notificationFlex {
   display:flex;
   border: none;
   padding: 24px;
}

.notificationWidgetFlex {
   display:flex;
   border: none;
   padding: 40px 24px 24px 44px;
   flex-direction: row;
   justify-content: space-between;
}

.notificationClose {
   margin-top: 28px;
   margin-left: -23px;
   height: fit-content;
}

.notificationWidgetClose {
   margin-top: -25px;
   height: fit-content;
}

.notifTitle{
   font-weight: 700;
   text-align: left;
   color: #111111;
}

.notifButtonWrapper {
   height: 40px;
   background: #111111;
   border-radius: 999px;
   font-family: Noto IKEA Latin;
   font-size: 12px;
   font-weight: 700;
   line-height: 16px;
   letter-spacing: 0em;
   text-align: center;
   color: #FFFFFF;
   display: flex;
   align-items: center;
   padding: 0px 24px;
   cursor: pointer;
 }

 .notifButtonWrapper:hover {
   color: #FFFFFF;
 }

 .notifNoMsgWrap {
   margin-top: 35px;
   display: flex;
   justify-content: center;
 }