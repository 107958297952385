.headerContainer {
    z-index: 5;
    justify-content: space-between;
    padding-left: 28vw;
}

.btnAction {
    margin-right: 28vw;
}

.btnAction>span {
    color: #0058A3;
    border-color: #0058A3 !important;

}

.btDisabled>span {
    color: #929292 !important;
    border: 1px solid #dfdfdf !important;
}

@media only screen and (max-width: 1200px) {
    .headerContainer {
        padding-left: 15vw;
    }

    .btnAction {
        margin-right: 15vw;
    }
}

@media only screen and (max-width: 800px) {
    .headerContainer {
        padding-left: 10vw;
    }

    .btnAction {
        margin-right: 20vw;
    }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent !important;
    height: 48px !important;
    align-items: center !important;
    border: none !important;
    border-radius: 4px !important;
    box-shadow: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    background: transparent !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer !important;
    caret-color: #000000;
}

.ant-select-selection-search-input {
    height: 46px !important;
}

.ant-select-selection-item {
    height: 48px;
    display: flex;
    align-items: center;
}

.anticon-search {
    display: none;
}

.ant-select-selection-placeholder {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #767676 !important;
}

.ant-select-disabled {
    pointer-events: none;
}

.ant-select-disabled .ant-select-selection-placeholder {
    color: #dfdfdf
}

.ant-picker-clear {
    opacity: 1;
    margin-right: 32px;
}

.radio {
    width: 33.33%
}

.ant-upload-list {
    margin: 40px 0 0 0;
}

.ant-upload.ant-upload-select {
    width: 100%;
}

.icDesktopPhotoParent .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 143px;
    height: 143px;
    border-radius: 4px;
    padding: 0px;
    margin-right: 24px;
    margin-top: 0;
}

.icDesktopPhotoParent .ant-upload-list-picture .ant-upload-list-item {
    float: left;
    width: 143px !important;
    height: 143px !important;
    border-radius: 4px !important;
    padding: 0px !important;
    margin-right: 24px;
    margin-top: 0;
    position: relative;
}

.icDesktopPhotoParent .ant-upload-list-item-thumbnail {
    width: 143px !important;
    height: 143px !important;
}

.icDesktopPhotoParent .ant-upload-list-item-thumbnail>img {
    object-fit: cover;
}

.icDesktopPhotoParent .ant-upload-list-picture .ant-upload-list-item-name {
    display: none;
}

.icDesktopPhotoParent .ant-upload-list-item-actions {
    position: absolute;
    top: 5px;
    right: 5px;
}

.icDesktopPhotoParent .ant-upload-list-item-actions>button {
    background-color: #cfcfcf70 !important;
    border-radius: 100px !important;
    height: 38px !important;
    width: 38px !important;
}

.icPhoto {
    color: #0058A3;
}

.icDesktopPhotoParent .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #0058A3;
}

.icDesktopPhotoParent .disableUpload .ant-upload.ant-upload-drag *{
    pointer-events: none !important;
    color: #DFDFDF !important;
    border-color: #DFDFDF !important;
}

.icDesktopPhotoParent .disableUpload .ant-upload.ant-upload-drag:hover {
    border-color: #DFDFDF !important;
    cursor: not-allowed !important;
}

.radio {
    width: 33.33%
}

.icDatePickerDropdown .ant-picker-date-panel,
.ant-picker-decade-panel,
.ant-picker-month-panel,
.ant-picker-quarter-panel,
.ant-picker-time-panel,
.ant-picker-week-panel,
.ant-picker-year-panel {
    width: 280px !important;
}

.icImagePreviewContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icImgPreview {
    height: 480px;
    width: 100%;
    object-fit: contain;
}

.ruSelect {
    margin-top: 40px;
    background: #FFFFFF !important;
    border: 1px solid #929292 !important;
    border-radius: 4px !important;
    height: 48px;
    padding: 0px;
}