.headerContainer {
    height: 48px;
    display: flex;
    z-index: 5;
    justify-content: space-between;
    border-bottom: 1px solid #DFDFDF;
}

.mobileBackButton {
    display: flex;
    width: 48px;
    height: 48px;
    margin-left: 12px;
    align-items: center;
    justify-content: center;
}

.mobileConnect {
    height: 48px;
    display: flex;
    align-items: center;
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #111111;
}

.iconsWrapper {
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 12px;
}

.icMobilePageTitle {
    height: 48px;
    display: flex;
    align-items: center;
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
}