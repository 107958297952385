.emptyMainText {
    font-size: 24px;
    color: #E00751;
    margin-top: 55px;
}

.emptySubText {
    line-height: 45px;
    margin-top: 0;
    margin-bottom: 0.5em;
    color: #929292;
    font-weight: 400;
    font-size: 16px;
}

.emptyDataIcon {
    width: 90px !important;
    height: 90px !important;
    color: #E00751;
}

.emptyMainTextMob {
    padding: 0px 24px;
    font-size: 18px;
    color: #E00751;
    margin-top: 45px;
    line-height: 30px;
}

.emptySubTextMob {
    padding: 0px 24px;
    margin-top: 0;
    margin-bottom: 0.5em;
    color: #929292;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
}

.emptyDataIconMob {
    width: 80px !important;
    height: 80px !important;
    color: #E00751;
}

.unauthorizedWrapper{
    text-align: center;
    padding: 80px 24px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}