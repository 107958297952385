.parent {
    max-width: 650px;
    height: calc(100vh - 112px);
    margin-top: 40px;
}

.title {
    padding-bottom: 40px;
    margin-bottom: 0px;
    font-weight: 700;
}

.dateContainer {
    display: flex;
    justify-content: space-between;
    gap: 22px;
    margin-bottom: 20px;
}

.dateInputBox {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dateLabel {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;
}

.dateInput {
    height: 48px;
    width: 100%;
    max-height: 48px !important;
    border-radius: 4px;
    border: 1px solid #929292;
    transition: box-shadow 100ms cubic-bezier(0.4, 0, 0.4, 1), border 100ms cubic-bezier(0.4, 0, 0.4, 1);
}

.dateInput:hover {
    border-color: #929292;
}

.dateInput:focus-within {
    box-shadow: none;
    border: 2px solid #0058a3;
}

.arrow {
    color: #443c3c73;
    height: 20px;
    width: 20px;
}

.dateInputError {
    border: 2px solid #e00751 !important;
}

.endDateErrorContianer {
    height: 18px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
}

.errorMessage {
    margin-left: 5px;
    color: #e00751;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 0px;
}

.noticeIcon {
    height: 16px;
    width: 16px;
    border-radius: 12px;
    background: #e00751;
    color: white;
    position: relative;
}

.errorIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    height: 24px;
    width: 24px;
    vertical-align: middle;
    fill: currentColor;
}

.messageBox {
    padding-bottom: 64px;
}

.loadingBall {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
}