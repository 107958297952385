.dbCardSmallRow {
    height: 24px;
}

.notifCardStyle {
    border: none !important;
}

.dbCardHeight {
    height: 320px !important;
}

@media only screen and (max-width: 1200px) {
    .projectsContentWrapper .dbCardStyle {
        width: calc((100vw - 176px)/3);
    }
}

@media only screen and (max-width: 800px) {
    .projectsContentWrapper .dbCardStyle {
        width: calc((100vw - 152px)/2);
    }
}

.highlightCard {
    border: 1px solid #E00751 !important;
}

.ant-card-body {
    padding: 16px !important;
}

.ant-card-bordered {
    border: 1px solid #DFDFDF;
}

.ant-card :hover {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.dashboardImagesContainer {
    position: relative;
}

.dashboardDefaultImageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 148px;
    margin-bottom: 16px;
    background-color: #F5F5F5;
}

.dashboardImagesWrapper {
    position: relative;
    height: 148px;
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
}

.dbImage {
    height: 148px;
    width: 100%;
    object-fit: cover;
}

.notifImageDivider {
    left: 50%;
    margin-top: 30px;
}

.dbIcNoImage {
    color: #929292;
    height: 40px;
}

.notifItemName {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #111111;
}

.notifItemTitle {
    margin-top: 20px;
    display: block;
    width: 100px;
    font-family: Noto IKEA Latin;
    font-size: 12px;
    font-weight: 400;
    line-height: 21.99px;
    text-align: left;
    color: #767676;
}

.notifItemContent {
    margin-top: 5px;
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #767676;
}

.dbItemContentWidth {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
    height: 32px;
    white-space: nowrap;
}

.dbCardItems {
    position: absolute;
    bottom: 4px;
    right: 4px;
    padding: 0px 4px;
    display: flex;
    align-items: center;
    background-color: #111111;
    color: #FFFFFF;
}

.dbFourteenBold {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
}

.notifClear {
    padding: 6px 7px
}

.notifButtonWrap {
    margin-top: 30px;
    display: flex;
    width: 100%;
    justify-content: right;
}

.ant-divider {
    border-top: 1px solid rgb(4 0 0 / 12%) !important;
}

.notificationPopupClose {
    margin-right: -14px;
    margin-top: -14px;
}    

.pdfDownloadButton {
    float: right;
    margin: 14px 16px 0px 16px;
}

.noImage {
    margin-top: 30px;
}