.iimContentWrapper {
    width: 50%;
}

@media only screen and (max-width: 1200px) {
    .iimContentWrapper {
        width: 60%;
    }
}

@media only screen and (max-width: 840px) {
    .iimContentWrapper {
        width: 75%;
    }
}

.emptyMainText {
    font-size: 24px;
}

.emptyMainTextSmall {
    font-size: 16px;
}

.emptySubText {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: #929292;
    font-weight: 400;
    font-size: 14px;
}

.emptyDataIcon {
    width: 90px !important;
    height: 90px !important;
    color: #DFDFDF;
}

.emptyDataIconSmall {
    width: 80px !important;
    height: 80px !important;
    color: #DFDFDF;
}

.emptyData {
    text-align: center;
    padding: 80px 8px;
    margin-top: auto;
    margin-bottom: auto;
}

.ecxlFlex {
    display: flex;
    gap: 5px;
}

.itemDragWrapper .ant-upload-list {
    margin: 0px;
}

.itemImportWrapper .ant-upload-list {
    margin: 40px 0 0 0;
}

.itemsTabLabel {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #484848;
    margin-bottom: 4px;
}

.itemsTabFormat {
    font-family: Noto IKEA Latin;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #767676;
    margin-top: 4px;
}

.iimErrorMessage {
    margin-top: 40px;
}