.csvStatusWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    padding: 20px;
    margin: 0 0 25px 0;
}

.csvStatusLeft {
    display: flex;
    align-items: center;
    gap: 28px;
}

.csvstatusFileWrapper {
    display: flex;
    gap: 40px;
    align-items: center;
}

.csvStatusFileDetails {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 12px;
    line-height: 18px;
}

.csvStatusRight {
    display: flex;
    align-items: center;
    gap: 16px;
}

.csvStatusRight svg {
    width: 18px;
}

.csvStatusSuccess {
    color: #0A8A00;
    display: flex;
    align-items: center;
    gap: 4px;
}

.csvStatusFail {
    color: #E00751;
    display: flex;
    align-items: center;
    gap: 4px;
}

.csvStatusIcon {
    width: 16px;
    height: 16px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.csvStatusCheckIcon {
    background-color: #0A8A00;
}

.csvStatusNoticeIcon {
    background-color: #E00751;
}

.csvStatusIcon>svg {
    width: 24px;
    height: 24px;
    color: #FFFFFF;
    position: absolute;
}

.csvStatusFail a {
    padding: 0 0 0 8px;
    color: #E00751;
    text-decoration: underline;
}

span.csvStatusFileName {
    font-size: 16px;
    line-height: 26px;
    word-break: break-all;
    padding-right: 25px;
}

.csvStatusLeft .csvIconWrapper svg {
    width: 48px !important;
    height: 43px !important;
}

.csvLinkWrapper {
    text-decoration: underline;
    cursor: pointer;
}

.btCsvStatusClose {
    height: 40px;
    width: 40px;
}