.headerContainer {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
    border-bottom: 1px solid #DFDFDF;
}

.headerLeftWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.applicationTitle {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000
}

.icOutbox {
    position: relative;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icOutbox>span {
    min-height: 0px !important;
    height: 40px !important;
    width: 40px !important;
}

.otherAppbarWrapper {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
}

.otherAppbarWrapper .mobileBackButton>span {
    padding: 0px;
}

.otherTitle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
}

.dbmOutboxCount {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-color: #0058A3;
    color: #FFFFFF;
    border-radius: 100px;
    font-family: Noto IKEA Latin;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
}