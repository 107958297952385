.promptWrapper {
    align-items: center;
}

.promptWrapper>div {
    min-width: 400px;
}

.prompt__heading {
    text-align: center !important;
}

.promptWrapper h2 {
    text-align: center !important;
}

.promptWrapper .prompt__content {
    overflow: hidden !important;
}

.txtDesc {
    height: 44px;
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 157.1%;
    padding-top: 8px;
    text-align: center;
    color: #000000;
    overflow: hidden;
    margin-bottom: 0;
}

.closeButtonIcon {
    position: absolute;
    top: 5px;
    right: 5px;
}

.closeButtonIcon>span {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.modalFooter {
    padding: 2rem !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 16px;
}

.modalFooterBtn {
    margin-bottom: 0 !important;
}