.topContainer {
    display: flex;
    justify-content: space-between;
    width: calc(100vw - 128px);
}

.leftWrapper {
    display: flex;
    align-items: center;
}

.search {
    margin-left: 40px;
    width: 317px !important;
}

.btAdAll {
    margin: auto 0px;
}