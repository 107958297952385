.csvImportModalWrapper .theatre {
    width: 35vw !important;
    max-height: 90vh !important;
    height: fit-content !important;
    min-width: 640px;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    margin: auto;
}

.csvImportModalWrapper .modal-body {
    padding: 0px 24px !important;
}

.csvImportModalWrapper .ant-upload-list-picture .ant-upload-list-item-thumbnail {
    width: 48px !important;
    cursor: default;
    pointer-events: none;
}

.csvImportModalWrapper .ant-upload-list-picture .ant-upload-list-item-name {
    display: block !important;
}

.csv-ic-delete-file {
    width: 48px !important;
    height: 48px !important;
}

.csvImportModalWrapper .csvColumnSelectWrapper {
    margin: 24px 0px;
}

.csvImportModalWrapper .csvColumnSelectText {
    margin-bottom: 4px;
    line-height: 20px;
}

.csvUploadStatusLeft {
    display: flex;
    align-items: center;
    gap: 15px;
}

.csvUploadStatusWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    padding: 7px 25px;
}