.icOptionalFields .accordion__item--active>.accordion__content {
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.icOptionalFields .accordion__item--active :focus {
    outline: none !important;
}

.icOptionalFields .accordion__item :focus {
    outline: none !important;
}