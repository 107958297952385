.icBti {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 27px;
    background-color: #FFDB00;
    box-shadow: 2px 2px 0px 0px #CC0008;
    font-family: Noto IKEA Latin;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
}

.icBtiLarge {
    height: 24px;
    width: 37px;
    font-size: 14px;
    margin-left: 0px !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.icBtiMl{ 
    margin-left: 8px;
    margin-top: 2px;
}

.icBtiNoMl{ 
    margin-left: 8px;
    margin-top: 0px;
}