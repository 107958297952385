.mobileSectionWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin-bottom: 40px;
    position: absolute;
    width: 327px;
    height: 226px;
    left: 24px;
    top: 100px;
}

.radioGap {
    margin-bottom: 24px;
}