.sectionWrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    height: calc(100vh - 154px);
    overflow-y: scroll
}

.accordion {
    margin-top: 40px;
}

.textArea {
    width: 100%;
    margin-bottom: 0;
}

.inputField {
    width: 100%;
    margin-bottom: 0;
}

.radioButtonWrapper {
    width: 100%;
}

.warningTitleWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.icNotice {
    width: 16px;
    height: 16px;
    background-color: #E00751;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px
}

.icNotice>svg {
    width: 24px;
    height: 24px;
    margin: -3px -3px;
    color: #FFFFFF;
}