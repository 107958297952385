.spModalBody {
    padding: 0px !important;
}

.spContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 72px);
    padding: 6px 48px 24px;
}

.spTopWrapper {
    display: flex;
    flex-direction: column;
}

.spContentWrapper {
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
}

.spTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #111111;
}

.spSubTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #484848;
}

.spLink:hover {
    cursor: pointer;
    text-decoration: underline;
}

.spLinkWrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.spLinkWrapper:hover {
    text-decoration: underline;
}

.spVersion {
    text-align: center;
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: #767676;
}

.qrBox {
    margin-top: 10px;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    width: fit-content;
}

.logoutButton {
    width: 100%;
    padding: 27px 25px;
}