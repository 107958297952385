.pillsContainer {
    display: flex;
    flex-wrap: wrap;
}

.pill {
    margin-right: 16px;
    margin-bottom: 16px;
}

.pillBorder {
    border: 1px solid #111111;
}