.sectionWrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
}

.titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ruSelect {
    margin-top: 40px;
    background: #FFFFFF !important;
    border: 1px solid #929292 !important;
    border-radius: 4px !important;
    height: 48px;
    padding: 0px;
}

.csCbNoComp {
    flex-direction: row !important;
    justify-content: unset !important;
    align-items: center !important;
    margin-top: 20px !important;
}

.csCbNoComp span:nth-child(2) {
    padding-top: 3px;
}


.btDeleteComparison {
    border-radius: 999px;
    height: 40px;
    color: #E00751;
}

.btDeleteComparison:hover {
    color: #E00751;
}

.btDeleteComparison>span {
    border: 1px solid #E00751 !important;
    color: #E00751;
    height: 40px;
}

.deleteInfoText {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.deleteInfoContainer {
    display: flex;
    align-items: center;
    margin-top: 17px;
}

.deleteNoticeIcon {
    width: 33px;
    height: 21px;
    color: white;
    background: #0058A3;
    border-radius: 100px;
    margin-right: 8px;
}