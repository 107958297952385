.imgPreviewCarousel .carousel-slide {
    height: 480px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #DFDFDF !important;
}

.icImgPreviewModalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icImgPreviewDownload {
    margin-top: 12px;
}