.marginBottomTen {
    margin-bottom: 10px;
}

.marginTopTen {
    margin-top: 10px;
}

.marginBottomFour {
    margin-bottom: 4px;
}

.cardStyle {
    width: 375px;
    border-radius: 8px;
    cursor: pointer;
}

.cardStyle:hover {
    background-color: #F5F5F5;
    box-shadow: 4px 4px 8px rgb(0 0 0 / 6%), -4px -4px 8px rgb(0 0 0 / 6%);
}

.highlightCard {
    border: 1px solid #E00751 !important;
}

.ant-card-body {
    padding: 24px 16px 24px 16px !important;
}

.ant-card-bordered {
    border: 1px solid #DFDFDF;
}

.ant-card :hover {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cardParentContainer .ant-popover-inner {
    box-shadow: none;
}

.ant-popover-arrow-content {
    --antd-arrow-background-color: #F5F5F5;
}

.iconWrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}