.dmParentContainer {
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
}

.dmDataContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    padding: 16px 0;
    flex-grow: 1;
    justify-content: space-between;
}

.dmSyncNowWrapper {
    display: flex;
    flex-direction: column;
    padding: 12px 8px;
    margin: 0 24px;
    border-radius: 8px;
    background: #F5F5F5;
}

.dmSyncNowWrapper>div:nth-child(2) {
    margin-bottom: 0 !important;
    font-size: 6px !important;
    margin-top: 4px !important;
    width: 100% !important;
}

.dmSyncNowWrapper>div:nth-child(2)>div>div {
    background: #DFDFDF !important;
}

.dmSyncNowWrapper>div:nth-child(2)>div>div>div {
    background-color: #0058A3 !important;
}

.dmSyncContentWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
}

.dmSyncContentWrapper>button>span {
    padding: 0 16px !important;
    color: #0058A3;
}

.dmTextWrapper {
    display: flex;
    margin-bottom: 4px;
}

.dmTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.dmSubtitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #484848;
}

.dmTabsContainer>div:nth-child(1) {
    margin: 0px 24px;
    width: auto;
}

.dmTabPanel {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 16px 24px;
    height: 76vh;
    overflow-y: scroll;
}

.dmCard {
    width: 100%;
    margin-bottom: 16px;
}

.dbBtnIc {
    width: 85%;
    left: calc(15%/2);
    right: calc(15%/2);
}

.dmHeaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 46px;
    padding-right: 46px;
}

.dmHeaderText {
    font-family: Noto IKEA Latin;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    padding-bottom: 30px;
}