.ant-select {
    height: 40px;
    background: #F5F5F5 !important;
    border: none !important;
    border-radius: 50px !important;
    padding: 0px 16px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    background-color: none !important;
    border: none !important;
    border-radius: 50px !important;
    background: #F5F5F5 !important;
    box-shadow: none !important;
    padding: 0px 16px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    background: #dfdfdf !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer !important;
    caret-color: transparent;
}

.ant-checkbox-group-item:last-child {
    margin-right: 0px !important;
}

.hfbFieldContainer {
    position: relative;
}

.compRightPadding {
    padding-right: 30px !important;
}

.compLeftPadding {
    padding-left: 75px !important;
}

.hfbPlaceholder {
    position: absolute;
    padding: 0px 24px;
    color: #111111;
    font-weight: bold;
    font-size: .76rem;
    left: 0;
    top: calc(50% - 10px);
    line-height: 19px;
    pointer-events: none;
}

.ant-select-arrow {
    color: #111111 !important;
}

.ant-select-selection-overflow {
    display: none !important;
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: #0058A3 !important;
    border-color: #0058A3 !important;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
    background-color: #0058A3 !important;
}

.itemsHfbTree {
    min-width: 423px !important;
}

.ant-tree-select-dropdown {
    padding: 12px 4px 12px 12px !important;
}

.ant-select-tree-list-holder {
    max-height: 340px !important;
}

.ant-select-tree-switcher {
    margin-right: 4px !important;
}

.ant-table-column-sorter {
    display: none;
}

.items_pills-container {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.demo-divider__hr-horizontal {
    margin: 24px 0px;
    border-block-start: #DFDFDF;
}

.items_header_wrapper {
    background-color: #FFFFFF;
    z-index: 5;
    padding: 20px 0px 0px 0px;
    width: calc(100vw - 134px);
}

.item_flexbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw - 134px);
}

.items_filters-container {
    display: flex;
    gap: 16px;
    width: calc(100vw - 134px);
}

.search_pill {
    width: 392px;
    margin-left: auto;
    margin-right: 16px;
}

.search_pill_items {
    width: 392px;
    margin-left: auto;
}

.search_pill_items .search__icon {
    display: none;
}

.search_pill_items .search__action-wrapper:last-child {
    display: none;
}

.table-align-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.items-demo-divider__hr-horizontal {
    margin: 18px 0px 18px 0px !important;
    border-top: 1px solid #DFDFDF66 !important;
}

.ant-table-empty .ant-table-tbody>tr.ant-table-placeholder>td {
    background-color: #FFFFFF !important;
}

.btnShowMore {
    width: 280px;
}

.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #DFDFDF !important;
    border-color: #d9d9d9 !important;
}

.input-field--disabled input {
    color: rgb(0 0 0 / 32%) !important;
}

.ant-picker-input>input[disabled] {
    color: rgb(0 0 0 / 32%);
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.text-area textarea:disabled {
    color: rgb(0 0 0 / 32%) !important;
}

.itemsComparisonCount {
    cursor: pointer;
}

.itemsTable .ant-table-body {
    overflow-x: hidden;
}

.itemsTable .ant-table {
    max-height: calc(100vh - 405px);
    padding-bottom: 16px;
    margin-bottom: -16px;
}

.itemsTableAccepted .ant-table {
    max-height: calc(100vh - 520px);
    padding-bottom: 16px;
    margin-bottom: -16px;
}

.itemsBodyOverflow .ant-table-body {
    overflow-y: hidden !important;
}