.troStartDateDropdown .ant-picker-dropdown {
    width: calc(100vw - 48px);
}

.troStartDateDropdown .ant-picker-date-panel,
.ant-picker-decade-panel,
.ant-picker-month-panel,
.ant-picker-quarter-panel,
.ant-picker-time-panel,
.ant-picker-week-panel,
.ant-picker-year-panel {
    width: calc(100vw - 48px);
}

.troStartDateDropdown .ant-picker-date-panel .ant-picker-content {
    width: 100%;
}