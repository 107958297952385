.sectionWrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
}

.radioButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
}