.ant-upload-list {
    margin: 40px 0 0 0;
}

.ant-upload.ant-upload-select {
    width: 100%;
}

.photoParent .upload-list-inline .ant-upload-list-item {
    float: left;
    width: calc((100vw - 84px) / 3);
    height: calc((100vw - 84px) / 3);
    margin: 0px 6px;
    padding: 0px;
    position: relative;
}

.photoParent .ant-upload-list-picture .ant-upload-list-item {
    float: left;
    width: calc((100vw - 84px) / 3) !important;
    height: calc((100vw - 84px) / 3) !important;
    margin: 0px 6px !important;
    padding: 0px !important;
    position: relative;
}

.photoParent .ant-upload-list-picture .ant-upload-span {
    position: relative;
}

.photoParent .ant-upload-list-item-thumbnail {
    width: calc((100vw - 84px) / 3) !important;
    height: calc((100vw - 84px) / 3) !important;
}

.photoParent .ant-upload-list-item-thumbnail>img {
    object-fit: cover;
}

.photoParent .ant-upload-list-picture .ant-upload-list-item-name {
    display: none;
}

.photoParent .ant-upload-list-item-actions {
    position: absolute;
    top: 5px;
    right: 5px;
}

.photoParent .ant-upload-list-item-actions>button {
    background-color: #cfcfcf70 !important;
    border-radius: 100px !important;
    height: 38px !important;
    width: 38px !important;
}

.photoParent .ant-btn-icon:not([disabled])>svg {
    fill: #E00751;
}

.photoParent .ant-btn-icon:not([disabled]):hover>svg {
    fill: #E00751;
}