.fcDateInput {
    height: 48px;
    width: 100%;
    max-height: 48px !important;
    border-radius: 4px;
    border: 1px solid #929292;
    transition: box-shadow 100ms cubic-bezier(0.4, 0, 0.4, 1), border 100ms cubic-bezier(0.4, 0, 0.4, 1);
}

.fcDateInput:hover {
    border-color: #929292;
}

.fcDateInput:focus-within {
    box-shadow: none;
    border: 2px solid #0058a3;
}

.fcDatearrow {
    color: #111111;
    height: 20px;
    width: 20px;
}

.fcDatePicker {
    visibility: hidden;
    height: 0;
    padding: 0;
    width: 0;
    position: absolute;
}

.fcAllFiltersTitle {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #111111;
}

.fcAllFiltersContainer {
    display: flex;
    flex-direction: column;
    height: 863px;
    padding: 24px 0px;
}

.fcAllFiltersText {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #111111;

}

.fcButtonsWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
}

.fcActionBtn {
    font-size: 12px;
    line-height: 16.6px;
    margin-bottom: 0 !important;
}

.accordionContainer {
    position: relative;
}

.accordionContainer>ul>li {
    margin-bottom: -12px;
}

.messageAccordion>li>div:nth-child(2) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.accordionContainer>ul>li>div:nth-child(2) {
    padding-top: 0px !important;
    padding-bottom: 1rem !important;
    gap: 0;
}

.accordion__item--active>.accordion__content {
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.accordion__item--active :focus {
    outline: none !important;
}

.accordion__item :focus {
    outline: none !important;
}

.dividerDefault {
    margin: 0px 0px 24px 0px !important;
}

.fc-accordion-divider__hr-horizontal {
    border: 0;
    border-top: 1px solid #DFDFDF;
    margin: 24px 0px 24px 0px !important;
}

.fcSectionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.fcCompetitorsectionWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.fcCompetitorSelect {
    background: #FFFFFF !important;
    border: 1px solid #929292 !important;
    border-radius: 4px !important;
    height: 48px;
}

.fcAllDateWrapper {
    height: 400px;
}

.fcAllDateWrapper .ant-picker-dropdown .ant-picker-dropdown-placement-bottomLeft {
    left: 605px !important;
    top: 270px !important;
    z-index: 400 !important;
}

.fcRadioButtonWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}