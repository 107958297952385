.sectionWrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
}

.ifNumberOfPieces {
    margin-top: 40px;
}

.swTimeRestricted {
    margin-top: 24px;
}