.projectTitle {
    font-family: Noto IKEA Latin;
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: -0.0042em;
    color: #111111;
    text-align: left;
    margin-bottom: 40px;
}

.dateTitle {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #111111;
    margin-bottom: 16px;
}

.date {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #111111;
    text-align: left;
}

.dateTitleWrapper {
    display: flex;
    gap: 64px;
}

.dateWrapper {
    display: flex;
    gap: 64px;
    margin-bottom: 40px;
}

.messageTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 4px !important;
}

.message {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 24px !important;
}

.buttonsWrapper {
    display: flex;
    gap: 16px;
    width: 100%;
}

.actionBtn {
    font-size: 12px;
    line-height: 16.6px;
    margin-bottom: 0 !important;
}

.itemWrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #DFDFDF;
    border-bottom: none;
    justify-content: center;
    padding: 16px;
    gap: 8px;
    width: 100%;
    position: relative;
}

.itemWrapper:last-of-type {
    border-bottom: 1px solid #DFDFDF;
}

.itemWrapper:hover {
    background: #f5f5f5;
}

.itemWrapper:hover>div {
    visibility: visible;
}

.removeItem {
    visibility: hidden;
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 12px;
    right: 12px;
    padding: 4px;
    cursor: pointer;
    color: #E00751;
}

.removeText {
    margin-bottom: 0px;
    margin-left: 8px;
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.itemTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
}

.itemContent {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
}

.accordionContainer {
    position: relative;
}

.accordionContainer>ul>li {
    margin-bottom: -12px;
}

.messageAccordion>li>div:nth-child(2) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.accordionContainer>ul>li>div:nth-child(2) {
    padding-top: 0px !important;
    padding-bottom: 1rem !important;
    gap: 0;
}

.removeAllItems {
    position: absolute;
    top: 24px;
    right: 48px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
}

.removeAllItems:hover {
    background: #f5f5f5;
    border-radius: 50px;
    color: #E00751;
}

.removeAllText {
    display: none;
    margin-bottom: 0px;
    margin-left: 8px;
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.removeAllItems:hover .removeAllText {
    display: block;
}

.dividerDefault {
    margin: 0px 0px 24px 0px !important;
}

.none {
    display: none;
}

.accordionItems {
    width: 100% !important;
}

.removedItemTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
    text-decoration: line-through;
    color: #484848;
}

.removedItemContent {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
    text-decoration: line-through;
    color: #484848;
}

.addItem {
    visibility: hidden;
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 12px;
    right: 12px;
    padding: 4px;
    cursor: pointer;
    color: #0A8A00;
}