.headerContainer {
  position: fixed;
  width: 100%;
  height: 60px;
  left: 0;
  top: 0;
  background: #FFFFFF;
  border-bottom: 1px solid #DFDFDF;
}

.navBarContentWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 64px;
  padding-right: 64px;
}

.navBarLeftContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.qualityCheckWrapper {
  height: 40px;
  background: #F26A2F;
  border-radius: 999px;
  font-family: Noto IKEA Latin;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  padding: 0px 24px;
  cursor: pointer;
}

.toCompeteLogo {
  width: 75px;
  height: 30px;
  margin-right: 10px;
}

.toCompeteClickableLogo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toCompeteFont {
  width: 90px;
  height: 24px;
}

.navBarRightContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.nameWrapper {
  height: 40px;
  border: 1px solid #111111;
  border-radius: 999px;
  font-family: Noto IKEA Latin;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #111111;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0px 24px;
  cursor: pointer;
}