.mobileContentOuterWrapper {
    height: 182Vh;
    padding: 40px 24px;
}

.mobileTitle {
    margin-top: 52px;
    height: 26px;
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #111111;
}

.mobileContentWrapper {
    max-height: calc(100% - 114Vh);
    margin-top: 32px;
    overflow: auto;
}

.mobileCountinueButton {
    height: 56px;
    bottom: 25px;
    position: fixed;
    width: 88%;
}

.ant-tree-switcher {
    position: relative;
    flex: none;
    align-self: stretch;
    width: 0px;
    margin: 0;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #0058A3 !important;
    border-color: #0058A3 !important;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
    background-color: #0058A3 !important;
}

.ant-tree .ant-tree-treenode {
    display: flex;
    align-items: flex-start;
    padding: 0 0 16px;
    outline: none;
}

.ant-tree-checkbox-input:focus+.ant-tree-checkbox-inner,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner {
    border-color: #d9d9d9;
}

.hfbHeaderWrapper {
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
    padding: 0px 24px;
}

.hfbHeaderWrapper .hfbMobileCloseButton>span {
    padding: 0px;
}

.changeHfbContentOuterWrapper {
    height: 182Vh;
    padding: 24px 24px;
}

.changeHfbTitle {
    height: 26px;
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #111111;
}