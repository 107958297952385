.contentWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 48px;
}

.sectionTitle {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #111111;
    margin-bottom: 16px;
}

.date {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #111111;
    text-align: left;
}

.dateTitleWrapper {
    display: flex;
    gap: 64px;
}

.dateWrapper {
    display: flex;
    gap: 64px;
    margin-bottom: 32px;
}

.ruItem {
    display: flex;
    height: 26px;
    align-items: center;
    justify-content: flex-start;
}

.message {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 32px;
}

.noMessage {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 32px;
    color: #767676;
}

.itemsContainer {
    padding-bottom: 104px;
}

.itemsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itemTitleWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.itemTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.select {
    width: 300px;
}

.footer {
    position: absolute;
    bottom: 0px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 96px);
    padding: 24px 0px;
    gap: 16px;
    z-index: 5;
}

.actionBtn {
    flex: 1;
}

.ruName {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.noticeContainer {
    display: flex;
    align-items: center;
}

.noticeIcon {
    width: 16px;
    height: 16px;
    color: white;
    background: #F26A2F;
    border-radius: 100px;
    margin-right: 8px;
}

.noticeText {
    font-family: Noto IKEA Latin;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.waringTitleWrapper {
    margin-top: 16px;
    margin-bottom: 32px;
}

.warningText {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #484848;
    white-space: pre-line;
}