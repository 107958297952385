.parent {
  display: block;
  position: relative;
}

.iconsWrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 64px;
}

.buttoniconsWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  gap: 4px;
  width: 124px;
  height: 44px;
  left: 16px;
  top: 21px;
  border: 1px solid #DFDFDF;
  border-radius: 4px;
  margin-right: 64px;
  cursor: pointer;
}

.buttoniconsWrapper:hover {
  background-color: #DFDFDF;
}

.tabsContainer>div:first-child {
  padding-left: 64px;
  padding-right: 64px;
  overflow-y: hidden;
}

.utnTabsPanel {
  padding: 0;
}

.button>span {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.iconcount {
  width: 20px;
  height: 22px;
  font-family: 'Noto IKEA Latin';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 157.1%;
  text-align: center;
  color: #000000;
}

.actionBtn {
  margin-right: 16px;
}

.tabPanel {
  height: 100vh;
  overflow: scroll;
  cursor: default;
}

.itemsTabPanel {
  padding-top: 0 !important;
}

.deleteBtn {
  margin-right: 16px;
  color: #E00751;
}

.deleteBtn:hover {
  color: #E00751;
}

.deleteBtn>span {
  border: 1px solid #E00751 !important;
}