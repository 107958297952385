.pillsContainer {
    width: calc(100vw - 276px);
}

.pillsContainer::-webkit-scrollbar {
    height: 6px;
}

.pillsContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(169 169 169 / 20%);
}

.pillsContainer::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
    border-radius: 8px;
    cursor: grab;
}

.pill {
    margin-right: 16px;
    margin-bottom: 16px;
}

.pillBorder {
    border: 1px solid #111111;
}