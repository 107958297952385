.udParent {
    padding: 48px 64px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: calc(100vh - 56px);
    overflow-x: hidden;
}

.udContentWrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 80vw;
}

.udContentWrapper .label-wrapper--text-input label {
    color:#484848;
}

.udLeftWrapper {
    flex: 1;
    min-width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-right: 48px;
}

.udRightWrapper {
    flex: 1;
    min-width: 50%;
    box-sizing: border-box;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 48px;
}

@media (max-width: 840px) {
    .udLeftWrapper {
        min-width: 100%;
        padding-right: 0;
    }

    .udRightWrapper {
        margin-top: 12px;
        min-width: 100%;
        padding-left: 0;
        padding-bottom: 64px;
    }
}

.udTitle {
    margin-bottom: 48px;
}

.udLabel {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.99px;
    text-align: left;
    margin-bottom: 2px;
    color: #484848;
}

.udSelectWrapper {
    display: flex;
    flex-direction: column;
}

.udSelector {
    background: #FFFFFF !important;
    border: 1px solid #929292 !important;
    border-radius: 4px !important;
    height: 48px;
    padding: 0px;
    width: 100%;
}

.udDisabledSelector {
    border: 1px solid #dfdfdf !important;
}

.udFormField {
    margin-bottom: 0;
}

.udTextField {
    position: relative;
}

.udContentWrapper .ant-upload .ant-upload-btn {
    display: flex;
    align-items: center;
}

.udContentWrapper .ant-upload.ant-upload-drag {
    height: 112px !important;
}

.udImgUploadWrapper .ant-upload-drag-container {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
}

.udImgUploadWrapper .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #0058A3;
}

.udImgUploadWrapper .udDisableUpload .ant-upload.ant-upload-drag * {
    pointer-events: none !important;
    color: #DFDFDF !important;
    border-color: #DFDFDF !important;
}

.udImgUploadWrapper .udDisableUpload .ant-upload.ant-upload-drag:hover {
    border-color: #DFDFDF !important;
    cursor: not-allowed !important;
}

.udImgUploadWrapper .ant-upload-list {
    margin: 16px 0 0;
    display: flex !important;
    width: 100% !important;
    overflow: auto !important;
    padding-bottom: 16px !important;
}

.udImgUploadWrapper .ant-upload-list-picture .ant-upload-list-item {
    float: left;
    width: 128px !important;
    height: 128px !important;
    border-radius: 4px !important;
    padding: 0px !important;
    margin-right: 16px;
    margin-top: 0;
    position: relative;
}

.udImgUploadWrapper .ant-upload-list-item-thumbnail {
    width: 128px !important;
    height: 128px !important;
}

@media (max-width: 1080px) {
    .udImgUploadWrapper .ant-upload-list-picture .ant-upload-list-item {
        width: 84px !important;
        height: 84px !important;
    }
    .udImgUploadWrapper .ant-upload-list-item-thumbnail {
        width: 84px !important;
        height: 84px !important;
    }
}

.udImgUploadWrapper .ant-upload-list-item-thumbnail>img {
    object-fit: cover;
}

.udImgUploadWrapper .ant-upload-list-picture .ant-upload-list-item-name {
    display: none;
}

.udImgUploadWrapper .ant-upload-list-item-actions {
    position: absolute;
    top: 5px;
    right: 5px;
}

.udImgUploadWrapper .ant-upload-list-item-actions>button {
    background-color: #cfcfcf70 !important;
    border-radius: 100px !important;
    height: 38px !important;
    width: 38px !important;
}

.udIcDelete {
    fill: #E00751 !important;
    height: 24px;
    width: 24px;
}

.udImgUploadWrapper .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #0058A3;
}

.udImgUploadIcon {
    margin-bottom: 0px;
}

.udImgUploadIcon>svg {
    width: 72px !important;
    height: 72px !important;
}

.udImgUploadHelperWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 8px;
}

.udImgUploadHelper {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.99px;
    text-align: left;
    margin: 0px;
}

.udImgUploadHelperTwo {
    font-size: 14px;
}