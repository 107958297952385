.loader {
    background: rgb(195 192 192 / 44%);
    padding: 0;
    position: fixed;
    height: 100vh !important;
    width: 100vw !important;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    z-index: 1100 !important;

}