.accIoSAddedItems {
    border-radius: 15px;
    border: 1px solid #dfdfdf;
}

.accIoSAddedItems .accordion-item-header {
    border-top: none !important;
    padding: 0px 20px !important;
}

.accIoSAddedItems .accordion__content {
    max-height: 50vh;
}

.accIoSAddedTable {
    margin-top: -24px !important;
}

.accIoSAddedTable .ant-table-body {
    height: 45vh;
    max-height: unset;
}