.outboxContainer {
    display: flex;
    flex-direction: column;
}

.outboxListWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 24px;
    height: calc(100vh - 138px);
    overflow-y: scroll;
}

.outboxCard {
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #DFDFDF;
    margin-bottom: 16px;
}

.cardContentWrapper {
    display: flex;
    align-items: center;
}

.errorObject {
    display: flex;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
}

.errorMessage {
    font-family: Noto IKEA Latin;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.icNotice {
    width: 16px;
    height: 16px;
    background-color: #E00751;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icNotice>svg {
    width: 24px;
    height: 24px;
    margin: -3px -3px;
    color: #FFFFFF;
}

.outboxImage {
    flex-basis: 20%;
    width: 72px;
    height: 72px;
    border-radius: 8px;
}

.outboxPlaceholderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    background: #F5F5F5;
    border-radius: 8px;
}

.outboxPlaceholderWrapper>svg {
    color: #929292;
}

.outboxImageWrapper {
    width: 72px;
    height: 72px;
    border-radius: 8px;
    border: 1px solid #DFDFDF;
}

.outboxImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.outboxTextWrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 8px;
    gap: 6px;
}

.outboxTitle {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    word-break: break-all;
    color: #000000;
}

.outboxSubtitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #767676;
}

.emptyTextWrapper {
    height: calc(100vh - 168px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.emptyText {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #929292;
}

.btDelete {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    width: 60px;
    color: #E00751;
}

.btUpload {
    position: absolute;
    width: 86%;
    height: 56px;
    bottom: 25px;
    left: 7%;
    right: 7%;
    background: #FFFFFF;
    border-radius: 999px;
}

.outboxContainer {
    display: flex;
    flex-direction: column;
}

.btUpload>span {
    height: 56px;
}

.obItemWrapper {
    padding: 4px 0px 0px 0px;
}

.obItemText {
    display: flex;
    gap: 8px;
    align-items: baseline;
}

.obDivider {
    margin: 8px 0px;
    border-top: 1px solid #DFDFDF;
}

.disabledCard {
    background: #DFDFDF;
}