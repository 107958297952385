.promptWrapper {
   align-items: center;
}

.prompt__heading {
   text-align: center !important;
}

.promptWrapper h2 {
   text-align: center !important;
}

.promptWrapper .prompt__content {
   overflow: hidden !important;
}

.txtDesc {
   font-family: 'Noto IKEA Latin';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 157.1%;
   padding-top: 8px;
   color: #000000;
   overflow: hidden;
}

.closeButtonIcon {
   position: absolute;
   top: 5px;
   right: 5px;
}

.closeButtonIcon>span {
   padding-left: 0.5rem !important;
   padding-right: 0.5rem !important;
}

.modalFooter {
   padding: 24px !important;
   display: flex;
   align-items: center;
   gap: 16px;
}

.modalFooterBtn {
   flex-grow: 1;
   flex-basis: 0;
   margin-bottom: 12px !important;
}

.mobilePromptWrapper {
   display: flex !important;
   flex-direction: column !important;
   justify-content: center !important;
   align-items: center !important;
   padding: 0px 24px !important;
   position: relative !important;
   width: 24rem !important;
}

.mobilePromptWrapper h2 {
   margin-top: 1rem !important;
}

.mobileCloseButtonIcon {
   position: absolute;
   top: 5% !important;
   right: 10% !important;
}

.mobileCloseButtonIcon>span {
   padding-left: 0.5rem !important;
   padding-right: 0.5rem !important;
}

.mobileModalFooter {
   display: flex !important;
   flex-direction: column !important;
   align-items: flex-start !important;
   padding: 24px 16px !important;
   gap: 12px !important;
}

.mobileTxtDesc {
   font-family: 'Noto IKEA Latin';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 157.1%;
   color: #000000;
   overflow: hidden;
}