.iconWrapper {
    position: absolute;
    bottom: calc(50% - 12px);
}

.iconWrapper:hover>.card {
    opacity: 1;
    visibility: visible;
    margin-top: 12px;
}

.card {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    opacity: 0;
    visibility: hidden;
    transition: ease 0.3s all;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 10%);
    background: #fff;
    justify-content: space-evenly;
    z-index: 3;
    position: absolute;
    padding: 16px;
    width: 376px;
    height: 172px;
    margin-left: -200px;
}

.cardImage {
    width: 140px;
    height: 140px;
}

.cardContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.cardTitle {
    color: #000000;
}

.cardButton > span {
    height: 40px;
    font-size: 12px;
}