.fcHeaderContainer {
    width: 100%;
    padding: 12px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.fcHeaderRegularFont {
    font-family: Noto IKEA Latin;
    font-size: 24px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.fcHeaderSubFont {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.fcHeaderSubLink {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    text-decoration: underline;
    cursor: pointer !important;
}

.headerTitleMargin {
    margin-top: 18px;
}

.qcShowComparison {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    top: 176px !important;
    position: absolute;
}

.qcShowComparisonText {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #484848 !important;
}

.qcTotalCount {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-left: 16px;
    color: #484848;
}