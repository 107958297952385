.projectDetails-theatre {
    border-radius: 8px !important;
    left: 22.5% !important;
    top: 48px;
    height: calc(100vh - 96px) !important;
    width: 55% !important;
}

@media only screen and (max-width: 1200px) {
    .projectDetails-theatre {
        border-radius: 8px !important;
        left: 10% !important;
        top: 48px;
        height: calc(100vh - 96px) !important;
        width: 80% !important;
    }
}

@media only screen and (max-width: 800px) {
    .projectDetails-theatre {
        border-radius: 8px !important;
        top: 0 !important;
        left: 0 !important;
        height: 100% !important;
        width: 100% !important;
    }
}

.divider_vertical {
    height: 26px;
    margin: 0px 0px 0px 16px;
    border: 0;
    border-right: 1px solid #DFDFDF;
}

.demo-divider__hr-horizontal {
    margin: 0px 0px 32px 0px;
    border: 0;
    border-top: 1px solid #DFDFDF;
}

.projectDetailsWrapper .ant-table-wrapper {
    margin-top: 20px;
    clear: both;
    max-width: 100%;
    padding-bottom: 96px;
}

.modal-header__title {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #111111;
}

.projectDetailsWrapper .theatre__content-wrapper {
    max-height: 654px;
}