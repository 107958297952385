.cuoContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 90px;
}

.cuoBtnWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    padding: 16px;
}

.cuoBtnSubmit {
    width: 50%;
}

.cuoSectionWrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 64px;
}

.cuoTopWrapper {
    gap: 20px;
}

.cuoTopWrapper .ant-divider {
    height: unset;
    margin: 6px 12px;
    border-inline-start: 2px solid rgba(5, 5, 5, 0.06);
}

.cuoSection {
    display: flex;
    flex-direction: column;
}

.cuoTitle {
    font-family: Noto IKEA Latin;
    font-size: 24px;
    font-weight: 700;
    line-height: 34.8px;
    letter-spacing: -0.0042em;
    text-align: left;
    color: #000000;
    margin: 12px 64px;
}

.cuoLabel {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.99px;
    text-align: left;
    color: #767676;
}

.cuoValue {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #000000;
}

.cuoUrl>a {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #0058A3;
}

.cuoMidWrapper {
    flex-direction: column;
    gap: 24px;
}

.cuoImgWrapper {
    display: flex;
    gap: 16px;
    padding-bottom: 16px;
    width: 100%;
    overflow: auto;
}

.cuoImg {
    width: 128px;
    height: 128px;
}

.icPageTitle {
    position: absolute;
    display: flex;
    align-items: center;
    left: 64px;
    top: 0;
    height: 60px;
    font-family: Noto IKEA Latin;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
}