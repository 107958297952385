.qcwPrompt .prompt__content-wrapper {
    padding: 0;
}

.qcwPrompt .prompt__content {
    padding: 12px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.qcwText {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 24px;
}

.qcwPrompt .ant-checkbox-wrapper {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    gap: 5px !important;
    padding: 0 !important;
    margin-bottom: 5px !important;
    margin-bottom: 24px !important;
}
