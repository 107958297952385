.sectionWrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    height: calc(100vh - 144px);
    overflow-y: scroll;
}

.icActionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.btDeleteComparison {
    border-radius: 999px;
    height: 40px;
    color: #E00751;
}

.btDeleteComparison:hover {
    color: #E00751;
}

.btDeleteComparison>span {
    border: 1px solid #E00751 !important;
    color: #E00751;
    height: 40px;
}

.itemName {
    margin-top: 8px;
    font-family: Noto IKEA Latin;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.itemType {
    margin-bottom: 40px;
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.summaryItemContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0px;
}

.summaryItemContainer:not(:last-child) {
    border-bottom: 1px solid #DFDFDF;
}

.summaryItemWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #111111;
}

.subTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #484848;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    width: 218px;
    text-overflow: ellipsis;
}

.editWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
}

.editText {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.warningTitleWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.icNotice {
    width: 16px;
    height: 16px;
    background-color: #E00751;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icNotice>svg {
    width: 24px;
    height: 24px;
    margin: -3px -3px;
    color: #FFFFFF;
}