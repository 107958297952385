.contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding-top: 90px;
    padding-bottom: 40px;
    padding-left: 28vw;
    padding-right: 28vw;
    height: 100vh;
    overflow-y: scroll;
}

.icPageTitle {
    position: absolute;
    display: flex;
    align-items: center;
    left: 64px;
    top: 0;
    height: 60px;
    font-family: Noto IKEA Latin;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
}

@media only screen and (max-width: 1200px) {
    .contentWrapper {
        padding-left: 15vw;
        padding-right: 15vw;
    }

    .icPageTitle {
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .contentWrapper {
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .icPageTitle {
        display: none;
    }
}

.regularFont {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.boldFont {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.sectionWrapper {
    display: flex;
    flex-direction: column;
}

.sectionTitleWrapper {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.sectionTitle {
    font-family: Noto IKEA Latin;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.optionalText {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 6px;
}

.sectionDescription {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
    color: #000000;
}

.disabled {
    border: 1px solid #dfdfdf !important;
}


.ruItemWrapper {
    display: flex;
    align-items: center;
}

.icPiecesWrapper {
    display: flex;
    gap: 16px;
}

.ifNumberOfPiecesWrapper {
    flex: 0.8;
    margin-bottom: 0px;
}

.ifNumberOfPieces {
    margin-top: 40px;
}

.ifNumberOfPiecesFood {
    margin-top: 40px;
    flex: 0.8;
}

.icUnitSelect {
    margin-top: 40px;
    flex: 0.4;
}

.btOnePiece {
    margin-top: 40px;
}

.btOnePiece>span {
    height: 56px;
}

.icIKEAPieces {
    margin-top: 12px;
}

.icIKEAPiecesText {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.swTimeRestricted {
    margin-top: 24px;
}

.timeRestrictedWrapper {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}

.radioButton {
    margin-top: 40px;
}

.dateInput {
    height: 48px;
    width: 100%;
    max-height: 48px !important;
    border-radius: 4px;
    border: 1px solid #929292;
    transition: box-shadow 100ms cubic-bezier(0.4, 0, 0.4, 1), border 100ms cubic-bezier(0.4, 0, 0.4, 1);
}

.dateInput:hover {
    border-color: #929292;
}

.dateInput:focus-within {
    box-shadow: none;
    border: 2px solid #0058a3;
}

.icon {
    color: #111111;
    height: 20px;
    width: 20px;
}

.titleCustomStyle {
    margin-top: 100px;
    margin-bottom: 40px;
}

.dateLabel {
    margin-bottom: 2px;
    line-height: 24px;
}

.textArea {
    margin-top: 40px;
    margin-bottom: 0;
}

.inputField {
    margin-top: 40px;
}

.ant-upload.ant-upload-select {
    width: 100%;
}

.btPhoto {
    width: 100%;
    margin-top: 40px;
}

.btPhoto>span {
    height: 56px;
}

.photoUploadContainer {
    margin-top: 40px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
}

.photoUploadContainer>span {
    width: 100%;
}

.photoTitle {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.photoSubTitle {
    font-family: Noto IKEA Latin;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 4px;
}

.icDelete {
    fill: #E00751 !important;
    height: 24px;
    width: 24px;
}

.icHeaderProjectContainer {
    display: flex;
    flex-direction: column-reverse;
    gap: 25px;
}

.icProjectDetailsTextContentWrapper {
    display: flex;
    justify-content: space-between;
}

.icHeaderContainer .button>span {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.icHeaderProjectContainer .button>span {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.icProjectDetailsTextWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.icProjectTextTitle {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #767676;
}

.icProjectTextDesc {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #000000;
    line-height: 22px;
}

.icProjectBtnWrapper {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    flex: auto;
    gap: 16px;
}

.icProjectDetailsWrapper {
    display: flex;
    justify-content: space-between;
}

.icHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.icProjectTextContentWrapper {
    display: contents;
}