.itemsContainer {
    margin-top: 24px;
}

.itemWrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #DFDFDF;
    border-bottom: none;
    justify-content: center;
    padding: 16px;
    gap: 8px;
    position: relative;
}

.itemWrapperLastChild {
    border-bottom: 1px solid #DFDFDF !important;
}

.itemWrapper:hover {
    background: #f5f5f5;
}

.connectedItemWrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #DFDFDF;
    justify-content: center;
    padding: 16px;
    gap: 8px;
    position: relative;
    margin-bottom: 24px;
}

.connectedItemWrapper:hover {
    background: #f5f5f5;
}

.itemTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
}

.itemContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
}

.itemContentWrapper {
    display: flex;
    align-items: center;
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
}

.imageIcon {
    bottom: calc(50% - 27px);
    margin-left: 8px;
    color: #0058A3;
}

.hoverCard {
    margin-left: -150px;
}

.btConnect {
    display: flex;
    align-items: center;
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0A8A00;
    cursor: pointer;
    
}

.btRemove {
    display: flex;
    align-items: center;
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #E00751;
    cursor: pointer;
}

.plus {
    color: #0A8A00;
    margin-right: 4px;
}

.disableFields {
    opacity: 0.4;
    pointer-events: none;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
    supported by Chrome, Edge, Opera and Firefox */
}

.sectionWrapper {
    display: flex;
    flex-direction: column;
}

.sectionTitleWrapper {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.sectionTitle {
    font-family: Noto IKEA Latin;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.sectionDescription {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 35px;
    color: #767676;
}