@font-face {
  font-family: Noto IKEA Latin;
  src: url("../src/fonts/NotoIkeaLatin/NotoIKEALatin-Regular.ttf") format("opentype");
  font-weight: normal;
}

body {
  margin: 0;
  line-height: 0%;
  overflow: hidden;
  font-family: Noto IKEA Latin !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span {
  font-family: Noto IKEA Latin !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}


::-webkit-scrollbar-track {
  background: #f1f1f1;
}


::-webkit-scrollbar-thumb {
  background: rgb(0 0 0 / 30%);
}

/* common styles */

.flexbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
}

.flexRowSpace {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-row {
  display: flex;
  justify-content: space-between;
}

.icon-align {
  margin-bottom: 1px;
}


.headerContainer {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 60px;
  left: 0;
  top: 0;
  background: #FFFFFF;
  border-bottom: 1px solid #DFDFDF;
}

.iconsWrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 64px;
}

.defaultContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 285px auto;

}

.itemPointer {
  cursor: pointer !important;
}

.defaultFont {
  font-family: Noto IKEA Latin;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}

.defaultSubFont {
  font-family: Noto IKEA Latin;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #484848;
}

.emptyDataIcon {
  width: 90px !important;
  height: 90px !important;
  color: #DFDFDF;
}

.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 240px !important;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color .3s;
}

/* table styles */

.ant-table-wrapper {
  margin-top: 30px;
  clear: both;
  max-width: 100%;
}

.table-striped-rows .ant-table-header {
  font-family: Noto IKEA Latin !important;
}

.table-striped-rows .ant-table-body {
  scrollbar-color: auto;
  font-family: Noto IKEA Latin !important;
}

.table-striped-rows tr:nth-child(odd) td,
td:hover {
  background-color: #FFFFFF !important;
}

.table-striped-rows tr:nth-child(even) td,
td:hover {
  background-color: #F5F5F5F5 !important;
}

.ant-table-thead>tr>th {
  position: relative;
  color: rgba(0, 0, 0, .85);
  font-weight: 500;
  text-align: left;
  background: #FFFFFF !important;
  border-bottom: 1px solid #f0f0f0;
  transition: background .3s ease;
}

.ant-table-tbody>tr>td {
  border-bottom: none !important;
  transition: background .3s;
}

.ant-table-thead>tr>th {
  position: relative;
  color: #484848 !important;
  font-weight: 400;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background .3s ease;
  font-size: 12px;
}

.ant-table-cell>.ant-checkbox-wrapper {
  display: flex !important;
  flex-direction: column !important;
  gap: 2px !important;
  align-items: center !important;
}

.ant-table-cell {
  font-size: .9em;
  color: #111111;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgb(255 255 255) !important;
  transform: translateY(-50%);
  transition: background-color .3s;
  content: "";
}

.table-image-right {
  margin-left: 5px;
}

.table-image-left {
  border: 1px solid #cdcdcd;
  height: 15px;
  margin-bottom: 2px;
  margin-right: 8px;
  width: 20px;
}

.table-number {
  text-align: right !important;
}

.table-text {
  text-align: center !important;
}

.table-header-padding {
  padding-left: 40px !important;
}

.ant-checkbox-wrapper {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
  gap: 5px !important;
  padding: 2px !important;
  margin-bottom: 5px !important;
}

.ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0px !important;
}

.ant-checkbox-wrapper::after {
  content: none !important;
}

.ant-checkbox-group-item:last-child {
  margin-right: 8px !important;
  margin-bottom: 0px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0058A3 !important;
  border-color: #0058A3 !important;
}

.ant-checkbox-checked::after {
  border-color: #0058A3 !important;
}

.ant-checkbox-group {
  width: -webkit-fill-available;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
}

/* ant dropdown menu */

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px !important;
}

.ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title {
  height: 100% !important;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0px !important;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}


.ant-menu-item-group-title {
  padding: 3px 10px !important;
  color: rgb(17 17 17) !important;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 1.5715;
  transition: all .3s;
}

.ant-popover {
  z-index: 50 !important;
}

.btn__label {
  white-space: nowrap !important;
}

.input-field__suffix-label {
  z-index: 0 !important;
  margin-inline-end: 0.5rem;
}

.prompt__content-wrapper {
  padding-top: 12px;
}

.ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, .85) !important;
  cursor: pointer;
}

.ant-picker-cell-disabled {
  color: rgba(0, 0, 0, .25) !important;
  pointer-events: none;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.tabs__tab:not([disabled]) {
  cursor: pointer !important;
}

.ant-checkbox-inner {
  top: 0;
  left: 0px;
  display: block;
  width: 24px !important;
  height: 24px !important;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all .3s;
}

.ant-tree-checkbox-inner {
  top: 0;
  left: 0px;
  display: block;
  width: 22px !important;
  height: 22px !important;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all .3s;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  height: 12px !important;
  width: 7px !important;
  margin-left: 1px !important;
}

.ant-tree-checkbox-inner:after {
  height: 12px !important;
  width: 7px !important;
  margin-left: 1px !important;
}

.ant-tree-checkbox {
  top: auto;
  margin: 1px 8px 0 0 !important;
}

.itemNo-common-design {
  display: inline-flex;
  width: 98px !important;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  background-color: #000000;
  color: #FFFFFF !important;
}

@media only screen and (min-width: 600px) {
  .application-toast {
    top: 5rem !important;
  }
}

/* notification styles */
.navbar-widget-icon {
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background-color: #FFDB00;
  box-shadow: none;
  border: none;
  cursor: pointer;
  margin-left: 12px;
  opacity: 1;
  z-index: 1;
  position: absolute;
  padding-left: 4px;
}

.navbar-widget-icon-notify {
  cursor: default;
  color: #111111;
  margin-top: -0.2em;
  font-size: 23px;
  line-height: 17px !important;
  position: relative;
  top: 1px;
}

.search__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.4, 1), opacity 200ms cubic-bezier(0, 0, 1, 1);
  transform-origin: 0 50%;
  opacity: 1;
  z-index: 0 !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.connectItemPanelModel .sheets__content-wrapper {
  overflow-x: hidden !important;
}

.projectsContentWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

@media only screen and (max-width: 1200px) {
  .projectsContentWrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 800px) {
  .projectsContentWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

.toggle__button {
  z-index: auto !important;
}

.competitorDropdown {
  max-height: 256px !important;
  min-height: 64px !important;
}

.competitorDropdown>div {
  display: flex;
  flex-direction: column;
  position: relative;
}

.competitorDropdown .ant-select-item-empty {
  height: 64px !important;
}

.ant-select-dropdown .ant-select-item-option-content {
  font-family: Noto IKEA Latin !important;
}