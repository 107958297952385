.ruWrapper {
    display: block;
    width: 50%;
    margin: 0 auto;
}

.ruWrapperMob {
    height: calc(91vh - 40px);
    position: relative;
    display: block;
    width: 86%;
    margin: 0 auto;
}

.mobileContentWrapper {
    display: flex;
    flex-direction: column;
    display: flex;
    padding: 0px;
    margin-bottom: 4px;

}

.ruTitle {
    height: 26px;
    margin-top: 92px;
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #111111;
}

.ruText {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #111111;
}

.countinueButtonMob {
    position: fixed;
    width: 85%;
    bottom: 24px;
}

.countinueButton {
    margin-top: 40px;
    position: static;
    width: 100%;
}

.sectionWrapper {
    display: flex;
    flex-direction: column;
}

.ruSelect {
    margin-top: 40px;
    background: #FFFFFF !important;
    border: 1px solid #929292 !important;
    border-radius: 4px !important;
    height: 48px;
    padding: 0px;
}

.disabled {
    border: 1px solid #dfdfdf !important;
}

.ruSelect>div {
    background: transparent !important;
    height: 48px !important;
    align-items: center !important;
}

.ruItemWrapper {
    display: flex;
    align-items: center;
}

.ruHeaderWrapper {
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
    padding: 0px 24px;
}

.ruMobileCloseButton {
    position: absolute;
    right: 0px;
    top: 16px;
}

.ruMobileCloseButton>span {
    padding: 0px !important;
    width: 40px;
    height: 40px;
}