.sectionWrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
}

.btPhoto {
    width: 100%;
    margin-top: 40px;
}

.btPhoto>span {
    height: 56px;
}

.errorObject {
    display: flex;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
}

.errorMessage {
    font-family: Noto IKEA Latin;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #E00751;
}

.icNotice {
    width: 16px;
    height: 16px;
    background-color: #E00751;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icNotice>svg {
    width: 24px;
    height: 24px;
    margin: -3px -3px;
    color: #FFFFFF;
}