.qcContentWrapper {
    position: absolute;
    width: 100%;
    padding: 24px 64px;
    top: 148px;
    overflow-y: hidden;
    padding-bottom: 40px;
    height: calc(100vh - 130px);
}

.qcItemNo {
    background-color: #000000;
    color: #FFFFFF;
    text-align: center;
}

.qcImageDetails-theatre {
    border-radius: 8px !important;
    left: 3% !important;
    top: 58px !important;
    height: calc(100vh - 96px) !important;
    width: 94% !important;
}

.qcItemDetails-item-image {
    width: 100%;
    height: 85%;
    object-fit: contain;
}

.qcImgeContainer {
    display: flex;
    gap: 46px;
    margin-top: 5rem !important;
}

.qcIkeaImage {
    width: 48% !important;
}

.qcCompImage {
    width: 48% !important;
}

.qcImageTitleText {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: #000000;
}

.qcSearchResultsImage {
    text-decoration-line: underline;
    color: #0058A3;
    cursor: pointer !important;
}

.qcContentWrapper .theatre__content-wrapper {
    overflow: hidden !important;
}

.qcCompImageRight {
    margin-right: 11px;
}

.qcCompDateAlign {
    text-align: center !important;
}

.qcEditCell {
    width: 95%;
    float: right;
}

.qcErr {
    margin-bottom: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.qcErr .input-field__wrapper>input {
    width: 100%;
}

.qcSuTopWrapper {
    top: 186px;
}

.qcTable {
    margin-top: 0;
}

.qcTable>* {
    z-index: 0;
}

.qcTable .ant-table-body {
    overflow-x: hidden;
    padding-bottom: 200px;
}

.qcTable .ant-table {
    max-height: calc(100vh - 350px);
    padding-bottom: 16px;
}

.qcSuTable {
    margin-top: 0;
}

.qcSuTable>* {
    z-index: 0;
}

.qcSuTable .ant-table {
    max-height: calc(100vh - 390px);
    padding-bottom: 16px;
}

.qcSuTable .ant-table-body {
    overflow-x: hidden;
    padding-bottom: 230px;
}

.qcPadding {
    padding: 0px !important;
}

.qc_flexbox {
    display: flex;
    align-items: center;
}

.qc-itemNo {
    display: inline-flex;
    width: 84px !important;
    align-items: center;
    padding: 4px 4px;
    background-color: #000000;
    color: #FFFFFF !important;
}

.qcNameWrapper-newItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px
}

.qcName-pencil {
    height: 32px;
    width: 32px;
    color: #0058A3;
}

.qcName-pencil>span {
    min-height: 32px;
}

.qcName-add-button {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #1890ff;
    text-decoration: underline;
}

/* Add button once disabled */
.qcName-add-button:disabled {
    cursor: not-allowed;
    text-decoration: none;
    color: #111111;
}

.qcName-add-button>span {
    min-height: 32px;
}