.table-align-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.searchResultsWrapper {
    position: relative;
    overflow-y: hidden;
    padding-bottom: 24px;
}

.searchResultsWrapper .ant-table-column-sorter {
    display: none;
}

.searchResultsWrapper .ant-table-empty .ant-table-tbody>tr.ant-table-placeholder>td {
    background-color: #FFFFFF !important;
}

.fcItemNo {
    background-color: #000000;
    color: #FFFFFF;
    text-align: center;
}

.fcPercentageWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}

.fcRedDot {
    border-radius: 999px;
    width: 6px;
    height: 6px;
    background-color: #E00751;
}

.fcGreenDot {
    border-radius: 999px;
    width: 6px;
    height: 6px;
    background-color: #0A8A00;
}

.fcSearchResultsImage {
    text-decoration-line: underline;
    color: #0058A3;
}

.fcTable {
    margin-top: 0;
}

.fcTable>* {
    z-index: 0;
}

.searchResultsWrapper .ant-table-row {
    cursor: pointer !important;
}

.fcLeftPadding {
    padding: 0px 12px !important
}

.fcPadding {
    padding: 0 !important;
}


.fcImageDetails-theatre {
    border-radius: 8px !important;
    left: 3% !important;
    top: 48px !important;
    height: calc(100vh - 96px) !important;
    width: 94% !important;
}


.fcItemDetails-item-image {
    width: 100%;
    height: 40vh;
    object-fit: contain;
}

.fcImgeContainer {
    display: flex;
    gap: 48px;
    margin-top: 5rem !important;
}

.fcImgeContainer .carousel-slide {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DFDFDF;
}

.fcIkeaImage {
    width: 50% !important;
}

.fcCompImage {
    width: 50% !important;
}

.fcImageTitleText {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: #000000;
}

.fcTable .ant-table-body {
    overflow-x: hidden;
    padding-bottom: 160px;
}

.fcTable .ant-table {
    max-height: calc(100vh - 495px);
    padding-bottom: 16px;
    margin-bottom: 0px;
}

.fcBodyOverflow .ant-table-body {
    overflow-y: hidden !important;
}