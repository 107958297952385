.dtDatePickerDropdown .ant-picker-date-panel,
.ant-picker-decade-panel,
.ant-picker-month-panel,
.ant-picker-quarter-panel,
.ant-picker-time-panel,
.ant-picker-week-panel,
.ant-picker-year-panel {
    width: 314px;
}

.tabTitle {
    padding-bottom: 32px;
    font-family: Noto IKEA Latin;
    font-size: 24px;
    font-weight: 700;
    line-height: 34.8px;
    letter-spacing: -0.0042em;
    text-align: left;
}