.nimImageDetails-theatre {
    border-radius: 8px !important;
    left: 20% !important;
    top: 48px !important;
    height: calc(100% - 6rem);
    width: 60% !important;
}

.nimImageDetails-theatre .modal-body {
    padding: 2rem;
}

.nimImgeContainer {
    display: flex;
    justify-content: center;
    gap: 48px;
}

.nimImgeContainer .carousel-slide {
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DFDFDF;
}

.nimIkeaImage {
    width: 70% !important;
}

.nimItemDetails-item-image {
    width: 100%;
    height: 400px;
    max-height: none !important;
    object-fit: cover;
}