.cardDetailsContainer {
    display: flex;
    flex-direction: column;
}

.cardDetailsBasicWrapper {
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin-bottom: 16px;
    height: calc(100vh - 48px);
    overflow-y: scroll;
}

.cardDetailsDate {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
}

.cardDetailsMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 8px;
}

.cardDetailsTextTitle {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 157.1%;
    color: #111111;
}

.cardDetailsTextContent {
    line-height: 24px;
}

.cardDetailsTextDesc {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 157.1%;
    color: #111111;
}

.cardDetailsItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 8px;
    margin-top: 1.2rem;
}

.cardDetailsItemsList {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px;
    position: relative;
    flex-direction: column;
    width: 100%;
}

.cardDetailsItemWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 0px;
    border-bottom: 1px solid #DFDFDF;
    justify-content: space-between;
    width: 100%;
}

.cardDetailsItemTitle {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 157.1%;
    color: #111111;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
}

.cardDetailsItemContent {
    height: 22px;
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 157.1%;
    color: #767676;
    margin-bottom: 0;
}

.cardDetailsItemTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 4px;
}

.projectDetailsSubTitleSontainer {
    display: flex;
}