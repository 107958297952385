.citTitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.citTitleLeftWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.citTitle {
    font-family: Noto IKEA Latin;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
}

.citTitleTwo {
    font-family: Noto IKEA Latin;
    font-size: 24px;
    font-weight: 700;
    line-height: 34.8px;
    letter-spacing: -0.0042em;
    text-align: left;
}

.citSubTitle {
    font-family: Noto IKEA Latin;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
}

.citTableCount {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #484848;
    margin-top: 8px;
}

.citTalbe {
    margin-top: 12px;
}

.citMt {
    margin-top: 48px;
}