.sectionWrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
}

@media only screen and (max-height: 480px) {
    .sectionWrapper {
        height: calc(100vh - 48px);
        padding-bottom: 40vh;
        overflow-y: scroll;
    }
}

.ifNumberOfPiecesWrapper {
    margin-bottom: 0px;
}

.ifNumberOfPieces {
    margin-top: 40px;
}

.ifNumberOfPiecesFood {
    margin-top: 40px;
}

.icUnitsDropdownTitle {
    margin-top: 24px;
    line-height: 24px;
}

.icUnitSelect {
    width: 100%;
    margin-top: 2px;
    background: #FFFFFF !important;
    border: 1px solid #929292 !important;
    border-radius: 4px !important;
    height: 50px;
    padding: 0px;
}

.icUnitSelect>div {
    background: transparent !important;
    height: 50px !important;
    align-items: center !important;
}

.icUnitSelect .ant-select-item .ant-select-item-option {
    background-color: #f5f5f5 !important;
}

.btOnePiece {
    margin-top: 40px;
}

.btOnePiece>span {
    height: 56px;
}

.disabled {
    border: 1px solid #dfdfdf !important;
}

.icUnitSelectDisabled {
    color: #dfdfdf !important;
}

.icIKEAPieces {
    margin-top: 12px;
}

.icIKEAPiecesText {
    font-weight: 700;
}