.dashBoardContainer {
    height: calc(100vh - 60px);
    width: 100vw;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
}

.contentWrapper {
    width: 100%;
    height: 100%;
}

.contentWrapper .tabs__list {
    margin: 0px 64px;
    width: calc(100vw - 128px);
}

.contentWrapper .tabs__panel {
    height: calc(100vh - 200px);
    padding: 24px 64px 64px 64px;
    overflow-y: scroll;
}

.fullHeight {
    height: calc(100vh - 112px) !important;
}

.tabs__tab--active::after {
    z-index: 0;
}

.tabs__panel {
    padding: 24px 0;
}

.carousel:hover .carousel__button {
    margin-top: -10px !important;
}

.scroll-indicator {
    display: none !important;
}

.radio_container {
    margin-bottom: 18px;
    gap: 30px;
}


.item-gap {
    gap: 30px;
  }

.toggleButtonDash {
    position: absolute;
    right: 64px;
    top: 11px;
}