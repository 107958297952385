.ant-checkbox+span {
    padding-right: 40px;
}

.suColumnTitleWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.suColumnTitleWrapper span:first-of-type {
    display: block;
    width: 198px;
    text-align: center;
}

.suColumnItemNo {
    text-align: center;
    margin-top: -15px;
    font-weight: bold;
}

.suSplitTable .ant-table-body {
    height: calc(100vh - 285px) !important
}