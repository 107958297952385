.table-align-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.searchResultsWrapper {
    position: relative;
    overflow-y: hidden;
    padding-bottom: 24px;
}

.searchResultsWrapper .ant-table-column-sorter {
    display: none;
}

.searchResultsWrapper .ant-table-empty .ant-table-tbody>tr.ant-table-placeholder>td {
    background-color: #FFFFFF !important;
}

.fcItemNo {
    background-color: #000000;
    color: #FFFFFF;
    text-align: center;
}

.fcPercentageWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}

.fcRedDot {
    border-radius: 999px;
    width: 6px;
    height: 6px;
    background-color: #E00751;
}

.fcGreenDot {
    border-radius: 999px;
    width: 6px;
    height: 6px;
    background-color: #0A8A00;
}

.fcSearchResultsImage {
    text-decoration-line: underline;
    color: #0058A3;
}

.fcTable {
    margin-top: 0;
}

.fcTable>* {
    z-index: 0;
}

.searchResultsWrapper .ant-table-row {
    cursor: pointer !important;
}

.fcLeftPadding {
    padding: 0px 12px !important
}

.fcPadding {
    padding: 0 !important;
}

.fcTable .ant-table-body {
    overflow-x: hidden;
    padding-bottom: 160px;
}

.fcTable .ant-table {
    max-height: calc(100vh - 495px);
    padding-bottom: 16px;
    margin-bottom: 0px;
}

.fcBodyOverflow .ant-table-body {
    overflow-y: hidden !important;
}