.hamIcon {
    width: 40px;
    height: 40px;
}

.hamIcon>span {
    min-height: 0px !important;
    height: 40px !important;
    width: 40px !important;
}

.navSheet {
    top: 0 !important;
    height: 100vh !important;
    max-height: 100vh !important;
    right: unset !important;
    left: 0 !important;
    width: 85% !important;
}

.modalBody {
    padding: 0px;
}

.userInfoWrapper {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.tUserName {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.tUserInfo {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #484848;
    padding-top: 4px;
}

.sectionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    outline: none !important;
    padding: 24px;
}

.sectionWrapper:active {
    background-color: #F5F5F5;
}

.textWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.tSectionTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.tSectionSubtitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.btnInstantComparison {
    padding: 24px;
}

.logoutBottomWrapper{
    width: 100%;
    position: absolute;
    bottom: 12Vh;
}