.sectionWrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
}

.ifNumberOfPieces {
    margin-top: 40px;
}

.radioButton {
    width: 100% !important;
    margin-top: 40px;
}